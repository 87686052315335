.contactForm {
    margin: 0 auto;
    width: 100%;
    

    .inputBlock {
        @include flex(column, flex-start, flex-start);
        margin-top: 40px;
        
        label {
            margin-bottom: 15px;
        }

        .inputField {
            width: 100%;
            border: none;
            border-radius: 20px;
            box-shadow: $shadow;
            padding: 20px;
            background-color: rgb(241, 232, 232)
        }

        .submitBtn {
            @extend .bookingButton;
            margin: 0 auto;
            color: white;
            background-color: $primaryRed;
        }
    }
}