// HEADER
.App {
    @include flex(column, space-between, space-between);
    min-height: 100vh;
}

.header {
    @include flex(row, space-between, flex-start);
    padding-top: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0%;
    // transform: translateX(-50%) translateY(0%);
    background-color: rgba(255, 255, 255, 0.93);
    transition: filter 1s;
    z-index: 99;

    &.filterShadow {
        filter: drop-shadow(0px 16px 40px rgba(154, 170, 207, 0.2));
    }

     .burgerIcon, .logoContainer  {
         margin-left: 50px;
         margin-right: 50px;

        @media (min-width: 700px) {
            margin-left: 100px;
            margin-right: 100px;
            
        }
    }

    .logoContainer {
        @include flex(row)
    }

    .logo {
        transition: width 1s;
    }

    .logoSmall {
        width: 50px;
        height: 50px;
    }
    .logoBig {
        width: 100px;
        height: 100px;
    }
    
    .logo_img {
        width: 100%;
    }

    .headline {
        display: none;

        @media (min-width: $bp-tablet) {
            display: block;
        }
    }
}



.menuContainer {
position: absolute;
transition: 1.5s;
width:100%;
padding-top: 40px;
background-color: rgba(128, 128, 128, 0.664);
top: 0;
left: -0%;

    @media (min-width: $bp-tablet) {
    display: none;
    }
}

.up {
    transform: translateY(-100%);
}

.menuContainerDesktop {
    display: none;

    @media (min-width: $bp-tablet) {
        @include flex(row, flex-start, flex-start);
        
        transition: visibility 0.3s;
    }

    .menuListDesktop {
        @include flex(row);

    li {
        margin-right: 50px;
        padding: 20px;
        font-weight: 500;
        transition: background-color 0.5s;
        cursor: pointer;
        &:hover{
            background-color: lightgray;
        }
    }

    }
}

.menuContainerDesktopIn {
    visibility: visible; 
}

.menuContainerDesktopOut {
    visibility: hidden;
}



.menuList {

    text-transform: uppercase;

    li {
        margin-top: 20px;
        background-color: rgba(0, 0, 0, 0.608);
        color: white;
        padding: 20px;
        font-weight: 500;
        cursor: pointer;
    }

    li:hover {
        background-color: black;
    }
}

.chapterHead {
    @include flex(row, flex-start);
    width: 100%;
    margin: 30px auto 50px;

    img {
        width: 50px;
        margin-right: 80px;
    }
}



.landingPage {
    margin-top: $marginToHeader;
    position: relative;
    //  z-index: -1;

    @media (min-width: $bp-tablet) {
        display: flex;
        justify-content: center;
        gap: 100px;
        width: 80%;
        margin: 200px auto 0;
        flex-wrap: wrap;        

    }
}

.article_lg {
    @include paper;
    margin-top: 150px;

    .articleHead {
        @include flex(column, flex-start, flex-start);
        margin-bottom: 30px;
    
        .subHead{
            // margin-left: 10px;
            font-style: italic;
        }

        .date {
        align-self: flex-end;
        font-style: italic;            
        }
    }
    .articleImg{
        
        @media (min-width: $bp-tablet){
            margin-left: 0;
            float: left;
            width: 300px;
        }
    }
    img {
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;

        width: 100%
    }

    .fileView {
        @include flex(row, center, flex-start);
        margin-top: 50px;
        gap: 50px;
        flex-wrap: wrap;

        h3 {
            width: 100%
        }

   
        .fileItem{
            @include flex();
            flex-basis: 150px;
            text-align: center;
        
            @media (min-width: $bp-tablet){
            flex-basis: 200px;
            }

        img {
            width: 50%
        }
        }
    }

    
}

.counselingBox {
    @include paper;
    margin-top: $marginToHeader;

    @media (min-width: $bp-desktop) {
        @include flex;
        
        .chapterHead {
        @include flex(row) 
        }
    }

}

.aboutBox {
    @include paper;
    margin-top: $marginToHeader;
}

.contactBox {
    @include paper;
    margin-top: $marginToHeader;
}

.aktuellWrapper {
    @media (min-width: $bp-tablet) {
        flex: 2;
        min-width: 500px;
    }
}

.twitterBox {
    @media (min-width: $bp-tablet) {
        flex: 1;
    }
}








