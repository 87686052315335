


.placesWrapper {
    margin-top: 50px;

    @media (min-width: $bp-desktop) {
        display: flex;
        flex-wrap: wrap;
        gap: $flex-gap-sm;
    }
}

.counselPlace {
    
    position: relative;
    width: 80%;
    margin: 0px auto;
    
    .paused {
        top: 250px;
        // font-weight: 1000;
        font-family: 'Lato-Bold';
        position: absolute;
        color: $primaryRed;
        transform: rotate(-45deg);
    }
    
    @media (min-width: $bp-desktop) {
        width: calc(50% - $flex-gap-sm);
    }


    .placeHead {
        @include flex(row, flex-start);
        margin-top: 60px;
        img {
            width: 50px;
            height: 100%;
        }

        h3 {
            text-transform: uppercase;
         
        }
    }

    .placeBody {

        .placeDescriptionItem {

            > *{
                margin-left: 20px;
                margin-bottom: 20px;
            }

            .counselBooking {
                @include flex(row, space-around);
                flex-wrap: wrap;
                gap: 40px;
                width: 100%;

            }
        }
    }
}