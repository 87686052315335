// VARIABLES
$marginToHeader: 200px;

$shadow: 
    0 3px 6px rgba(0,0,0,0.16),
    0 3px 6px rgba(0,0,0,0.23); 
$shadowInset: 
    inset 0 3px 6px rgba(0, 0, 0, 0.019),
    inset 0 3px 6px rgba(0, 0, 0, 0.233);
$morph: 
    inset -0px -3px 6px rgba(255,255,255, 0),
    inset 0px 3px 6px rgb(163,177,198,0),
    9px 9px 16px rgb(163,177,198,0.6),
    -9px -9px 16px rgba(255,255,255, 0.5);
$morphHover: 
    inset -4px -4px 9px rgba(255,255,255, 0.5),
    inset 4px 4px 9px rgb(163,177,198,0.6),
    0px 3px 6px rgb(163,177,198,0),
    -0px -3px 6px rgba(255,255,255, 0);
$bp-tablet: 700px;
$bp-desktop: 1024px;
$flex-gap-sm: 50px;
$primaryRed: #800000;


// MIXINS
@mixin flex($direction: column, $just: center, $align: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $just;
    align-items: $align;
}

@mixin paper(){
    // box-shadow: $shadow;
    width: 80%;
    margin: 0px auto 50px;
    border-radius: 50px;
    text-align:justify; 
    
    @media (min-width: $bp-tablet) {
        width: 70%;
        
    }

    @media (min-width: $bp-desktop){
        max-width: $bp-desktop;
    }
}

