@font-face {
    font-family: "Montserrat";
    src: url("../assets/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
    font-family: "NotoSans";
    src: url("../assets/fonts/NotoSans-Regular.ttf");
}

@font-face {
    font-family: "Oxygen";
    src: url("../assets/fonts/Oxygen-Regular.ttf");
}

@font-face {
    font-family: "Roboto";
    src: url("../assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Lato";
    src: url("../assets/fonts/Lato-Regular.ttf");
}

@font-face {
    font-family: "Lato-Bold";
    src: url("../assets/fonts/Lato-Bold.ttf");
}

html {
    font-size: 62.5%;
}

body {
    font-size: 1.6em;
    font-family: "Lato";

    @media (min-width: $bp-tablet) {
        font-size: 1.8em;
    }
}

a {
    cursor: pointer;
}

.exLink{
    color: blue;
}

.menuContainer {
    font-size: 1.5em;
}

p {
    line-height: 1.5;
}


