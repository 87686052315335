.heroWrapper {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    
   .heroViewer {
        @include flex(row);
        gap: 10PX;
        width: 300%;
        transform: translateX(0%);
        transition: all 1s;
        
        .slide {
            height: auto;
            width: 101%;
            border-radius: 40px;
            font-size: 2em;
            text-transform: uppercase;
            overflow: hidden;
            // font-weight: 700;
            position: relative;

            img {
                width: 100%;
                height: 100%;
            }

            span {
                position: absolute;
                top: 75%;  /* position the top  edge of the element at the middle of the parent */
                left: 35%; /* position the left edge of the element at the middle of the parent */
                transform: translate(-50%, 0%); 
                color: white;
                font-weight: 500;
                background-color: rgba(0, 0, 0, 0.588);
                padding: 10px
            }
            span:nth-of-type(2){
                color: red
            }
        }
   
    }
}

.menuHero {
    width: 100%;
    display: none;
    transition: 0.8s;

    @media (min-width: $bp-tablet) {
        display: block;
    }

    ul {
    @include flex(row, space-around, center);
    
        img {
        width: 100px;
        height: 100px; 
        }
    }

     .heroItem {
        @include flex(column, space-around);
        width: 200px;
        height: 200px;
        border-radius: 50px;
        box-shadow: $morph ;
        transition: 0.5s;

         &:hover{
             box-shadow: $morphHover ;
         }
    }

}

.menuHeroOut {
    transform: translateX(-110%)
}



.aktuellWrapper {
    @include paper;


    .articlePreview{
        padding-bottom: 30px;
        border-bottom: 1px solid black;
    }


    .preInfo{
        @include flex(row, space-between);
        font-size: 0.8em;
        
    }
}


.twitterBox {
     @include paper;

    .tweetContainer {
        position: relative;
       
        margin: 0 auto;
        max-width: 500px;
    }

}