.burgerIcon{
    @include flex;
    width: 30px;
    
    span {
        width: 100%;
        height: 2px;
        border-bottom: 3px solid black;
        margin: 2px;
    }

    p {
        font-size: 1rem;
    }

    @media (min-width: $bp-tablet) {
        display: none;
    }
}

.centerText {
    text-align: center
}

.inactiveText {
    color: lightgray
}

.closeBtn {
    @include flex;
    width: 80px;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.533);
    color: white;
    border-radius: 50%;
    font-weight: 700;
    margin: 30px auto;
    cursor: pointer;
    
}
.closeBtn:hover {
    background-color: black;
}

.backIcon {
    @include flex(row, center, center);
    position: fixed;
    height: 30px;
    bottom: 30px;
    padding: 10px;
    border-radius: 10px;
    color: $primaryRed;
    font-weight: 600;
    background-color: white;
    gap: 20px;    
    width: 120px;
    box-shadow: $morph;
    cursor: pointer;
    
    &:hover {
        color: white;
        background-color: $primaryRed;
    }

    @media (min-width: 1400px) {
        left: 50px;
        top: $marginToHeader;
        
    }


}



.headline{
    transition: 0.5s;
    position: absolute;
    left: 250px;
    text-transform: uppercase;
    font-size: 2.5rem;

    @media (min-width: $bp-desktop) {
        font-size: 4rem;
    }
}

.headlineOut {
    transform: translate(500px, -200px);
    // visibility: hidden;
}

.heroWrapper {
    @media (min-width: $bp-tablet) {
        display: none;
    }
}


.infoBox {
    @include paper;
    margin-top: $marginToHeader;
    
    .innerBox {
        text-align: center;
    }


}

.list {
    margin-left: 20px;
    list-style-type: disc;
    

    li {
        padding:10px;
    }

}

.fat {
    font-weight: 700;
}

.italic {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.strike {
    text-decoration: line-through;
}

.seperator {
    width: 30%;
    height: 1px;
    border: 1px solid black;
    margin: 20px;
}

.bookingButton {
    padding: 10px;
    border: 1px solid black;
    border-radius: 20px;
    font-weight: 700;
    font-size: 0.8em;
    transition: all 1s;
    cursor: pointer;

    &:hover {
        background-color: black;
        color: white;
    }

}

.readOn {
    @include flex(row, flex-end);
    width:90%;
    font-weight: 600;
    cursor: pointer;
}



.cookieBox {
    img {
        width: 50px;
    }

    .variant {
        @include flex(row, space-between);
    
        >p {
            width: 50%;
        }

        img {
            cursor: pointer;
        }
    }
}

.mailSent {
    @include flex;
    margin: 50px auto;
    width: 70%;
    overflow: hidden;

    img {
        transform: translateX(-200%);
        width: 50%;
        transition: all, 1s;
    }

    img.move {
        transform: translateX(0)
    }
}

