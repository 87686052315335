ul {
 list-style: none;
 margin: 0;
 padding: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

img {
    width: 100%;
    // height: 100%;
}